<template>
  <div class="home">
    <HelloWorld class="dashboard" msg="Welcome to Your Vue.js App"/>
    <StoreComp/>
  </div>
</template>

<script>

import HelloWorld from '@/components/HelloWorld.vue'
import StoreComp from '@/components/store_comp.vue'


export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    StoreComp
  }
}
</script>


<style>
body {
  background-color: #F1F4FA;
}
</style>


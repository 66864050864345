<template>
    <div class="Store">
        <h2>sge</h2>
    </div>
</template>

<script>
export default {
name: 'StoreComp',
}
</script>


<style scoped>
.Store {
    position: fixed; /* Фиксированное положение */
    right: 0; 
    top: 50%; /* Располагаем по вертикали по середине */
    /* bottom: ; */
    transform: translateY(-50%); /* Смещаем на половину высоты элемента наверх, чтобы быть точно по центру */
    background-color: #fff; /* Цвет фона */
    padding: 20px; /* Отступы внутри элемента */
    height: 95%;
    margin-right: 1rem;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 1em;
    width: 82%;
  }
</style>
